import {FirebaseClient} from "../javascript.lib.mojo-base/firebase/realtime-database/clients/FirebaseClient";
import {Injectable} from "@angular/core";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {AppClient, IAppClient} from "../javascript.lib.mojo-base/model/AppClient";
import {FirebaseConnectionService} from "../common/service.firebase-connection/FirebaseConnectionService";

@Injectable(
  {providedIn: 'root'}
)
export class ClientService {
  private static _log = LoggerFactory.build( 'ClientService' );
  clients: AppClient[];

  constructor(public firebase: FirebaseConnectionService) {
  }

  async write(client: IAppClient) : Promise<void> {
    return await FirebaseClient.write(this.firebase, client.clientKey, client);
  }

  async read(clientKey: string) : Promise<AppClient> | null {
    return await FirebaseClient.read(this.firebase, clientKey);
  }

  async readAll() : Promise<AppClient[]> | null  {
    this.clients = await FirebaseClient.readAll(this.firebase);
    return this.clients;
  }

  client(clientKey: string) : AppClient {
    return this.clients.filter(c => c.clientKey === clientKey)[0];
  }
}
