import {FirebaseValueObject, IMMValueObject} from "../../firebase/realtime-database/FirebaseValueObject";
import {AppTypedReferenceString} from "../cg/core/AppTypedReference";
import {IAppClusterAnswer} from "./AppClusterAnswer";
import {AppQuestionSet} from "../AppQuestionSet";
import {AppAnswerSet} from "../AppAnswerSet";
import {QuestionKey} from "../QuestionKey";
import {IAppAnswer} from "../AppAnswer";
import {EAppReferenceType} from "../cg/core/AppReferenceType";
import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";

export interface IAppClusterAnswers extends IMMValueObject {
  score: number;
  answers: { [key: AppTypedReferenceString]: IAppClusterAnswer; };
}

export class AppClusterAnswers extends FirebaseValueObject<IAppClusterAnswers> {

  private _log: ILogger = LoggerFactory.build('AppClusterAnswers');

  constructor(value: IAppClusterAnswers) {
    super(value);
    if (value) {
      this.value = value;
    }
  }

  static buildValue(selfId: string): IAppClusterAnswers {
    return {
      _self: {
        id: selfId,
        type: EAppReferenceType.answer_cluster
      }, _meta: {
        updates: []
      },
      score: null,
      answers: {}
    };
  }

  static build(selfId: string): AppClusterAnswers {
    return new AppClusterAnswers(AppClusterAnswers.buildValue(selfId));
  }

  update(answerSet: AppAnswerSet) {

    for (const answerKey of Object.keys(answerSet.answerByKey)) {

      const answer = answerSet.answerByKey[answerKey];
      const typedReference = answer.toAnswerReference();
      let clusterAnswer: IAppClusterAnswer = this.value.answers[typedReference.toString];
      if (!clusterAnswer) {
        clusterAnswer = {
          _self: typedReference.value, _meta: {
            updates: []
          }, answer: answer.value,
        }
        this.value.answers[typedReference.toString] = clusterAnswer;
      }
      clusterAnswer.answer = answer.value;
    }
  }

  toAnswerSet(questions: AppQuestionSet): AppAnswerSet {

    const answerSetValue: { [key: QuestionKey]: IAppAnswer; } = {}

    for (const clusterAnswerKey of Object.keys(this.value.answers)) {
      let clusterAnswer: IAppClusterAnswer = this.value.answers[clusterAnswerKey];
      const questionKey = clusterAnswer.answer.questionKey;
      const question = questions.getQuestionById(questionKey);

      // if the question is a ternary, but we find a yes/no answer with that question id...
      if (question?.isTernary && clusterAnswerKey.includes("boolean")) {
        clusterAnswer = this.tryFindYesNoAnswerAndMapToYesNoNa(clusterAnswerKey);
      }

      answerSetValue[questionKey] = clusterAnswer.answer;
    }

    return new AppAnswerSet(questions, answerSetValue);
  }

  private tryFindYesNoAnswerAndMapToYesNoNa(clusterAnswerKey: string) : IAppClusterAnswer | null {

    // does yes/no/na answer for that id exists?
    let yesNoNaAnswer = this.value.answers[clusterAnswerKey.replace("boolean", "ternary")];
    if (yesNoNaAnswer) {
      return yesNoNaAnswer;
    }

    // map yes/no to yes/no/na
    const answer = this.value.answers[clusterAnswerKey];
    if (answer) {
      this._log.info("roll-it", answer);
      answer._self.type = EAppReferenceType.answer_ternary;
      answer.answer.value = answer.answer.value ? 1 : 0;
    }
    this._log.info("roll-it", answer);
    return answer;
  }
}
