import {Component, Input, OnInit} from '@angular/core';
import {ILogger} from "../javascript.lib.mojo-base/log/Logger";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute, Router} from "@angular/router";
import {AppRouteManifest} from "../app/AppRouteManifest";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";

@Component({
  selector: 'page-password-reset',
  templateUrl: 'password-reset.page.html',
  styleUrls: ['password-reset.page.scss']
})
export class PasswordResetPage implements OnInit {

  private _log: ILogger = LoggerFactory.build( 'PasswordResetPage' );

  public emailAddress: string = null;

  onPasswordResetTriggered() {
    this._log.debug( 'onPasswordResetTriggered' );
    AppRouteManifest.HOME.navigate(this.router);
  }

  async ngOnInit() {
    const emailAddress = await AppRouteManifest.PASSWORD_RESET.getEmailAddress(this._route);
    if(emailAddress) {
      this.emailAddress = emailAddress;
    }
  }

  constructor(public router: Router,
              private _route: ActivatedRoute,
              public dialog: MatDialog,
              public snackBar: MatSnackBar ) {}
}
