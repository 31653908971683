import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {BaseApplicationContext} from "../../services.application-context/BaseApplicationContext";
import {MatSidenav} from "@angular/material/sidenav";
import {BaseLogger} from "../../../javascript.lib.mojo-base/log/BaseLogger";
import {AppInfo} from "../../../AppInfo";
import {SessionContextProvider} from "../../../service.session-context/session-context-provider";
import {Subscriptions2} from "../../../javascript.lib.mojo-base/util/Subscriptions2";
import {MatIcons} from "../../util/MatIcons";
import {PropertyService} from "../../../service.property/property-service";
import {AppClient} from "../../../javascript.lib.mojo-base/model/AppClient";
import {FirebaseConnectionService} from "../../service.firebase-connection/FirebaseConnectionService";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatListModule} from "@angular/material/list";
import {SideMenuProvider} from "../../../service.side-menu/side-menu-provider";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {SessionContextState} from "../../service.session-context/BaseSessionContext";
import {UserService} from "../../../service.user/user-service";
import {ClientService} from "../../../service.client/client-service";

export interface IMenuItem {
  matIcon: string;
  disabled(): boolean;
  label(): string;
  onClick(): Promise<void>;
}

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['side-menu.component.scss'],
  standalone: true,
  imports: [
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    NgForOf,
    MatButtonModule,
    NgIf
  ]
})
export class SideMenuComponent implements OnInit, OnDestroy {

  @Input('content') content:any;
  @Input() homeDisabled:boolean = false;
  @Input() sidenav: MatSidenav = null;
  public name: string = "?";
  public version: string = "?";
  public clientKey: string = "?";
  public subscriptions = new Subscriptions2();
  public clients: AppClient[] = [];

  protected readonly MatIcons = MatIcons;

  constructor(public router: Router,
              public applicationContext: BaseApplicationContext,
              public sessionContext: SessionContextProvider,
              public propertyService: PropertyService,
              public appInfo: AppInfo,
              public firebase: FirebaseConnectionService,
              public sideMenuModel: SideMenuProvider,
              public userService: UserService,
              public clientService: ClientService
  ) {
  }

  onClick(menuItem: IMenuItem) {
    menuItem.onClick();
    if(this.sidenav) {
      this.sidenav.toggle();
    }
  }

  async onClientChanged(clientKey: string) {
    if (this.sessionContext.canChangeClient) {
      this.sessionContext.clientKey = clientKey;
      if (!this.sessionContext.isAdministrator) {
        this.sideMenuModel.onSelectProperty();
      } else {
       // this.sideMenuModel.onGoToBackOffice();
      }
    }
  }

  ngOnInit() {
    const codeVersion = this.appInfo.version;
    this.subscriptions.subscribe(this.sessionContext.stateSubject, (state: SessionContextState) => {
      if (state === SessionContextState.UserIsReady) {
        this.clientKey = this.sessionContext.clientKey;
        if (this.sessionContext.canChangeClient) {
          this.subscriptions.subscribe(this.sessionContext.clientKeySubject, (clientKey) => {
            return this.refreshClients();
          });
        }
      }
    });
    this.subscriptions.subscribe(this.propertyService.propertySubject, () => {
      this.name = this.appInfo.name;
      const productDetails = this.propertyService.productDetails;
      this.version = (productDetails)
        ? `${codeVersion} / ${productDetails} / ${BaseLogger.CORRELATION_ID}`
        : `${codeVersion} / ${BaseLogger.CORRELATION_ID}`;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private async refreshClients() : Promise<void> {
    const allClients = await this.clientService.readAll()
    if (this.sessionContext.isAdministrator) {
      this.clients = allClients;
    } else {
      this.clients = allClients.filter(c => this.sessionContext.availableClientKeys.includes(c.clientKey));
    }
    AppClient.array.sortById(this.clients);
  }
}
