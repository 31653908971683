import {AppTypedReference, AppTypedReferenceString} from "../cg/core/AppTypedReference";
import {AppParentChild, IAppParentChild} from "./AppParentChild";
import {NocoClusterId} from "../../nocodb/model/NocoCluster";

export class AppParentChildSet {

  values: AppParentChild[] = [];
  valuesById: {[id: AppTypedReferenceString]: AppParentChild} = {};

  public findParentOfChild( child: AppTypedReference ): AppParentChild|null {
    for( const candidate of this.values ) {
      if( child.equals( candidate.child ) ) {
        return candidate;
      }
    }
  }

  getChildren( parent: AppTypedReference, sorted = true ): AppTypedReference[] {
    const children: AppParentChild[] = [];

    for( const candidate of this.values ) {
      if( parent.equals( candidate.parent ) && !candidate.value.trashed ) {
        children.push( candidate );
      }
    }

    if( sorted ) {
      children.sort( ( a: AppParentChild, b: AppParentChild ) => {
        return a.value.sequence - b.value.sequence;
      } );
    }

    return children.map( (e: AppParentChild) => {
      return e.child;
    });
  }

  getParentChild(parent: AppTypedReference, child: AppTypedReference ): AppParentChild {
    for( const candidate of this.values ) {
      if( parent.equals( candidate.parent ) && child.equals( candidate.child ) ) {
        return candidate;
      }
    }
    return null;
  }

  constructor( value: { [key: AppTypedReferenceString]: IAppParentChild; } ) {
    for(const key of Object.keys(value)) {
      const objectValue: IAppParentChild = value[key];
      const parentChild = new AppParentChild(objectValue);
      if (parentChild.value?.deleted) {
        continue;
      }
      this.values.push(parentChild);
      this.valuesById[key] = parentChild;
    }
  }
}
