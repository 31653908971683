

<div *ngIf="!sessionContext.isAuthenticated">


  <div aclass="component-list">

    <form class="form__"
          [formGroup]="authLinkForGroup"
          (ngSubmit)="onAuthLinkSubmit()"
    >

      <div aclass="component-list-item">
        <div style="width: 100%; height: 12px;">
        </div>
      </div>

      <div aclass="component-list-item">
        <div cclass="form-input">
          <mat-form-field appearance="fill"
                          style="width: 100%">
            <input
              matInput
              placeholder="Email"
              [formControl]="formControls.email"
              type="text"
            >
          </mat-form-field>
        </div>
      </div>


      <div *ngIf="authLinkError" aclass="component-list-item">
        <div aclass="form-error">
          {{authLinkError}}
        </div>
      </div>

      <div aclass="component-list-item" style="text-align: right;">
        <button mat-raised-button color="primary" type="submit">Send Authentication email</button>
      </div>

      <div aclass="component-list-item">
        <div style="width: 100%; height: 8px;">
        </div>
      </div>


    </form>

  </div>



</div>


