import {ValueObject} from "../../model/ValueObject";
import {NocoDbProjectProxy} from "../NocoDbProjectProxy";
import {NocoEnumeratedType} from "./NocoEnumeratedType";
import {IEnumeratedConstant} from "../../model/EnumeratedConstant";
import {IListingReponse} from "../ListingReponse";
import {IEnumScore} from "../../model/AppQuestion";

export interface INocoEnumeratedValue {
  Id: number;
  Description: string;
  EnumeratedTypeId: number;
  Score: number;
  CodeAsNumber: number;
  CodeAsString: string;
  ShowCapexInOptionalEssentialSubsection?: boolean;
}

export class NocoEnumeratedValue extends ValueObject<INocoEnumeratedValue>{

  constructor(value: INocoEnumeratedValue | null) {
    super(value);
    if (value) {
      this.value = value;
    }
  }

  protected onSetValue(value: INocoEnumeratedValue | null) {
  }

  toEnumeratedConstant(): IEnumeratedConstant {
    const codeAsNumber: number = this.value.CodeAsNumber ? this.value.CodeAsNumber : this.value.Id;
    const codeAsString: string = this.value.CodeAsString ? this.value.CodeAsString.toString() : `${this.value.Id}`;
    return {
      codeAsNumber,
      codeAsString,
      label: this.value.Description,
      popupLabel: null, // when null, will not be rendered in the popup
      showCapexInOptionalEssentialSubsection: this.value.ShowCapexInOptionalEssentialSubsection
    };
  }

  toEnumScore(): IEnumScore {
    const codeAsNumber: number = this.value.CodeAsNumber ? this.value.CodeAsNumber : this.value.Id;
    return {
      codeAsNumber,
      score: this.value.Score
    };
  }
}

export class NocoEnumeratedValueSet {

  private static readonly tableName: string = 'enumerated_value';

  enumeratedValues: NocoEnumeratedValue[] = [];
  enumeratedValuesById: {[id: number]: NocoEnumeratedValue} = {};
  _valuesByType: {[typeId: number]: NocoEnumeratedValue[] } = {};

  constructor( public value: IListingReponse<INocoEnumeratedValue> ) {
    for( const rowValue of value.list ) {
      const reference = new NocoEnumeratedValue( rowValue );
      this.enumeratedValues.push( reference );
      this.enumeratedValuesById[rowValue.Id] = reference;
    }
  }

  getValues(questionType: NocoEnumeratedType): NocoEnumeratedValue[]|null {

    const questionTypeId = questionType.value.Id;
    let values = this._valuesByType[questionTypeId];

    if(!values || values.length === 0) {
      values = this.enumeratedValues.filter(v => v.value.EnumeratedTypeId === questionTypeId);
      this._valuesByType[questionTypeId] = values;
    }

    return values;
  }

  static async getValue(proxy: NocoDbProjectProxy): Promise<IListingReponse<INocoEnumeratedValue>> {
    return proxy.getView<INocoEnumeratedValue>(this.tableName);
  }

  static async build(proxy: NocoDbProjectProxy): Promise<NocoEnumeratedValueSet> {
    const value = await NocoEnumeratedValueSet.getValue(proxy);
    return new NocoEnumeratedValueSet(value);
  }
}
