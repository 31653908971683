import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ILogger} from "../../../javascript.lib.mojo-base/log/Logger";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {NotificationDialogComponent} from "../component.notification-dialog/notification-dialog";
import {MatDialog} from "@angular/material/dialog";
import {LoggerFactory} from "../../../javascript.lib.mojo-base/log/LoggerFactory";

@Component({
  selector: 'password-reset',
  templateUrl: 'password-reset.component.html',
  styleUrls: ['password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  private _log: ILogger = LoggerFactory.build( 'PasswordResetComponent' );

  hide = true;

  @Output() passwordResetTriggered = new EventEmitter<void>();

  passwordResetControls = {
    email: new FormControl(null, [ Validators.required, Validators.email ] ),
  };

  @Input() set emailAddress(value: string | null) {
    if (value && value !== 'null') {
      this.passwordResetControls.email.setValue(value);
    }
  }

  emailPasswordFormGroup = new FormGroup({
    email: this.passwordResetControls.email,
  });

  public emailControlError: string = null;


  private _getEmailControlError(): string|null {

    if(this.passwordResetControls.email.hasError('required')) {
      return 'Email address required';
    }

    if(this.passwordResetControls.email.hasError('email')) {
      return 'Email address not valid';
    }

    return null;
  }

  async onSendPasswordResetEmail() {

    this.emailControlError = this._getEmailControlError();
    if(this.emailControlError) {
      return;
    }

    await this.afAuth.sendPasswordResetEmail(this.passwordResetControls.email.value);

    const dialog = NotificationDialogComponent.open(this.dialog, {
      message: 'You should get a password reset email shortly. Check your spam folder.',
      title: 'Reset Email Sent',
    });

    dialog.afterClosed().subscribe(result => {
      this.passwordResetTriggered.emit();
    });

  }

  ngOnInit(): void {
  }

  constructor(public afAuth: AngularFireAuth,
              public dialog: MatDialog) {}
}
