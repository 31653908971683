import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app.module';
import {environment} from "../environments/environment";
import {DevelopmentConsoleLogger} from "../javascript.lib.mojo-base/log/DevelopmentConsoleLogger";
import {ProductionConsoleLogger} from "../javascript.lib.mojo-base/log/ProductionConsoleLogger";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {TestConsoleLogger} from "../javascript.lib.mojo-base/log/TestConsoleLogger";
import {SignInParams} from "../common/module.base-components/component.sign-in/SignInParams";

if( environment.isProduction ) {
  enableProdMode();
  LoggerFactory.init(ProductionConsoleLogger.factory);
} else if( environment.isTest ) {
  LoggerFactory.init(TestConsoleLogger.factory);
} else {
  LoggerFactory.init(DevelopmentConsoleLogger.factory);
}

async function initApp() {
  SignInParams.initFromWindow(window);
  platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
}

initApp();



