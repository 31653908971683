import {FirebaseValueObject, IMMValueObject} from "../../firebase/realtime-database/FirebaseValueObject";
import {EAppReferenceType} from "../cg/core/AppReferenceType";
import {NocoClusterId} from "../../nocodb/model/NocoCluster";

export interface IAppCluster extends IMMValueObject {
  clusterTypeId: NocoClusterId;
  name: string;
  responsibility: 'landlord'|'tenant';
  completed: boolean;
  core: boolean;
  optional: boolean;
  include: boolean;
  essential: boolean;
  weight: number;
  mobilityWeight: number;
  visualWeight: number;
  auditoryWeight: number;
  cognitiveWeight: number;
  score: number;
  comment?: string;
}

export class AppCluster extends FirebaseValueObject<IAppCluster>{

  public static readonly TYPE = EAppReferenceType.cluster;

  protected onSetValue(value: IAppCluster | null) {
    super.onSetValue(value);
  }

  get showOptionalToggle() : boolean {
    return this.value.optional && !this.value.essential;
  }

  get scoreAndRecommendAsEssential() : boolean {
    return this.value.essential && !this.value.optional;
  }

  constructor(value: IAppCluster) {
    super(value);
    if(value) {
      if (!value.comment) {
        value.comment = null;
      }
      this.value = value;
    }
  }
}
