export enum EProductType {
  anPostCombined = "an-post-combined",
  anPostDsu = "an-post-dsu",
  anPostMailCentre = "an-post-mail-centre",
  banking = "banking",
  facilities = "facilities",
  failteIrelandAccommodation = "failte-ireland-accommodation",
  failteIrelandAttractions = "failte-ireland-attractions",
  failteIrelandOutdoorSpaces = "failte-ireland-outdoor-spaces",
  failteIrelandRetail = "failte-ireland-retail",
  foodAndBeverage = "food-and-beverage",
  generic = "generic",
  manufacturing = "manufacturing",
  manufacturingCampus = "manufacturing-campus",
  postOffice = "post-office",
  publicRealm = "public-realm",
  retail = "retail",
  banking_2024 = "banking-2024",
  hotels_2024 = "hotels-2024",
  unity = "unity",
}

export interface IProductTypeOption {
  key: EProductType;
  value: string;
}

export class ProductTypeOptions {
  static Options: IProductTypeOption[] = [{
    key: EProductType.banking_2024,
    value: "Bank"
  }, {key: EProductType.hotels_2024, value: "Hotel"}, {key: EProductType.facilities, value: "Office"},];

  static getLabel(productType: EProductType): string | null {
    return ProductTypeOptions.Options.find(o => o.key === productType)?.value;
  }

  static getProductType(label: string): EProductType {
    return ProductTypeOptions.Options.find(o => o.value === label)?.key ?? null;
  }

  static ensureLabel(label: string): string {
    const type = this.getProductType(label);
    return (type) ? this.getLabel(type) : null;
  }
}

