import {Component, Input} from "@angular/core";
import {AppProperty} from "../../../javascript.lib.mojo-base/model/AppProperty";
import {EProductType} from "../../../javascript.lib.mojo-base/model/ProductType";
import {MatIcons} from "../../util/MatIcons";

@Component({
  selector: 'property-details',
  templateUrl: 'property-details.html',
  styleUrls: ['property-details.scss']
})
export class PropertyDetails {

    @Input() property: AppProperty;

    get label() : string | null {
      return this.property.label;
    }

    get icon() : string {
      const productType = this?.property?.value?.productType ?? EProductType.banking_2024;

      if (productType === EProductType.banking_2024) {
        return MatIcons.icons.bank;
      } else if (productType === EProductType.hotels_2024) {
        return MatIcons.icons.hotel;
      }

      return MatIcons.icons.building;
    }
}
