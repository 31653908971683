<div *ngIf="!sessionContext.isAuthenticated" cclass="sign-in-wrapper">

  <maintenance></maintenance>

  <div cclass="logical-unit">

    <div aclass="component-list">
      <div aclass="component-list-item">
        <div aclass="form-header">
          Log in
        </div>
      </div>
    </div>

    <mat-tab-group style="padding-left: 16px; padding-right: 16px;" [selectedIndex]="0">

      <mat-tab label="Username & Password" *ngIf="authenticationConfig.usernamePassword">

        <authenticate-email-password
          (signInCompleted)="onSignInCompleted()"
          (passwordResetRequested)="onResetPassword($event)"
        >
        </authenticate-email-password>

      </mat-tab>

      <mat-tab label="Microsoft" *ngIf="authenticationConfig.microsoft">
        <authenticate-microsoft (signInCompleted)="onSignInCompleted()"></authenticate-microsoft>
      </mat-tab>

      <mat-tab label="Google" *ngIf="authenticationConfig.google">
        <authenticate-google (signInCompleted)="onSignInCompleted()"></authenticate-google>
      </mat-tab>

      <mat-tab label="Phone" *ngIf="authenticationConfig.textMessage" >
        <authenticate-phone (signInCompleted)="onSignInCompleted()" ></authenticate-phone>
      </mat-tab>

      <mat-tab label="Email link" *ngIf="authenticationConfig.emailLink">
        <authenticate-email-link (signInCompleted)="onSignInCompleted()"></authenticate-email-link>
      </mat-tab>

    </mat-tab-group>

  </div>

</div>


<div *ngIf="sessionContext.isAuthenticated">
  Logged in as: {{sessionContext.username}}
</div>
