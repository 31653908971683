import {ValueObject} from "./ValueObject";
import {ISearchable} from "../../common/interfaces/ISearchable";

export interface IAppClientMetaData {
  version?: number[];
}

export interface IAppClient {
  _meta?: IAppClientMetaData;
  clientKey: string;
  name: string;
  domain: string;
  shortCode: string;
}

export class AppClient extends ValueObject<IAppClient> implements ISearchable {

  private _searchableString: string = null;

  constructor(value: IAppClient | null = null,
              public clientKey: string | null = null) {
    super(value);
  }

  public static array = {
    sortById: (clients: AppClient[]) => {
      clients.sort((a, b) => {
        return a.clientKey.localeCompare(b.clientKey);
      })
    }
  }

  static buildClientKey(shortCode: string): string {
    return shortCode.split('').reverse()
      .map(char => char.charCodeAt(0).toString())
      .reduce((current, previous) => "" + previous + "" + current);
  }

  get searchableString(): string {
    if (null !== this._searchableString) {
      return this._searchableString;
    }
    this._searchableString = `${this.value.name}${this.value.shortCode}${this.value.clientKey}${this.value.domain}`.toLowerCase();
    return this._searchableString;
  }

  protected onSetValue(value: IAppClient | null) {
  }
}
