import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AppAnswer} from "../../../javascript.lib.mojo-base/model/AppAnswer";
import {EnumeratedConstantReference, IEnumeratedConstant} from "../../../javascript.lib.mojo-base/model/EnumeratedConstant";
import {AppQuestion} from "../../../javascript.lib.mojo-base/model/AppQuestion";
import {MatDialog} from "@angular/material/dialog";
import {DialogData, QuestionTernaryDialogComponent} from "./question-ternary-dialog/question-ternary-dialog";
import {DialogConfig} from "../../angular/DialogConfig";
import {LoggerFactory} from "../../../javascript.lib.mojo-base/log/LoggerFactory";
import {BaseQuestion} from "../BaseQuestion";


@Component({
  selector: 'question-ternary',
  templateUrl: 'question-ternary.html',
  styleUrls: ['question-ternary.scss']
})
export class QuestionTernaryComponent extends BaseQuestion implements OnInit, AfterViewInit, OnDestroy {

  _log = LoggerFactory.build( 'QuestionTernaryComponent' );

  selectedOption: IEnumeratedConstant|null = null;

  @Output() change = new EventEmitter<QuestionTernaryComponent>();

  @Input() set disabled(value: boolean) {
    super.disabled = value;
    if(this.disabled) {
      this.answer.value.value = null;
      this.answer.value.needsAnswer = false;
      this.selectedOption = null;
    }
  }

  private _initSelectedOption() {

    if(typeof this.answer.value.value === "number") {

      const currentValue: number = this.answer.value.value as number;

      if(EnumeratedConstantReference.yes.codeAsNumber === currentValue) {
        this.selectedOption = EnumeratedConstantReference.yes;
      } else if(EnumeratedConstantReference.no.codeAsNumber === currentValue) {
        this.selectedOption = EnumeratedConstantReference.no;
      } else {
        this.selectedOption = EnumeratedConstantReference.notApplicable;
      }

    } else {
      this.answer.value.value = null;
    }
  }


  onOpenDialog(): void {
    const data: DialogData = {
      answer: this.answer
    };

    const dialogRef = this.dialog.open(QuestionTernaryDialogComponent, {
      minWidth: DialogConfig.minWidth,
      panelClass: QuestionTernaryDialogComponent.PANEL_CLASS,
      data,
    });

    dialogRef.afterClosed().subscribe(result => {
      this._log.debug('dialog was closed');
      this._initSelectedOption();
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.validateType(AppQuestion.TYPE_TERNARY);

    this._initSelectedOption();

    if(this.answer.dependant) {
      this._dependantSubscription = this.answer.dependant.getSubject().subscribe( (dependant: AppAnswer ) => {
        const isTruthy = dependant.isTruthy(this.answer.question.value.dependant);
        this._log.debug( 'ngOnInit', dependant, 'isTruthy: ' + isTruthy );
        this.disabled = !isTruthy;
      } );
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy()
  }

  ngAfterViewInit(): void {
  }

  constructor(public dialog: MatDialog) {
    super();
  }
}
