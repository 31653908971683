

<div *ngIf="!sessionContext.isAuthenticated">


  <div aclass="component-list">

    <form class="form__"
          [formGroup]="emailPasswordFormGroup"
          (ngSubmit)="onEmailPasswordSubmit()"
    >

      <div aclass="component-list-item">
        <div style="width: 100%; height: 12px;">
        </div>
      </div>


      <div aclass="component-list-item">
        <div cclass="form-input">
          <mat-form-field appearance="fill"
                          style="width: 100%">
            <input
              matInput
              placeholder="Email"
              [formControl]="emailPasswordControls.email"
              type="text"
            >
          </mat-form-field>
        </div>
      </div>

      <div aclass="component-list-item">
        <div cclass="form-input">

          <mat-form-field appearance="fill"
                          style="width: 100%">
            <input
              matInput
              placeholder="Password"
              [formControl]="emailPasswordControls.password"
              [type]="hide ? 'password' : 'text'"
            >
            <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </mat-form-field>

        </div>
      </div>

      <div *ngIf="authenticateError" aclass="component-list-item">
        <div aclass="form-error">
          {{authenticateError}}
        </div>
      </div>


      <div aclass="component-list-item" style="text-align: right;">
        <button mat-raised-button type="button" (click)="onResetPassword()" style="margin-right: 8px;">Reset Password</button>
        <button mat-raised-button type="submit" color="primary" >Log in</button>
      </div>

      <div aclass="component-list-item">
        <div style="width: 100%; height: 8px;">
        </div>
      </div>


    </form>

  </div>


</div>


