import {EAppReferenceType} from "./AppReferenceType";
import {ValueObject} from "../../ValueObject";
import {MMBaseException} from "../../../util/MMBaseException";

export type AppTypedReferenceString = string;

export interface IAppTypedReference {
  type: EAppReferenceType;
  id: string;
}

export class AppTypedReference extends ValueObject<IAppTypedReference>{

  public toString: AppTypedReferenceString;
  public sequence: number = -1;

  static buildValue( reference: AppTypedReferenceString ): IAppTypedReference {

    const indexOfColon = reference.indexOf( ":");

    if(indexOfColon === -1) {
      return {
        type: EAppReferenceType.none,
        id: reference,
      }
    }

    const referenceType = reference.substring( 0, indexOfColon );
    const id = reference.substring( indexOfColon + 1 );

    if(referenceType.length === 0) {
      throw MMBaseException.build( "CGTypedReference", "0 === referenceType.length",  {
        reference: reference
      } );
    }

    if(id.length === 0) {
      throw MMBaseException.build( "CGTypedReference", "0 === id.length",  {
        reference: reference
      } );
    }

    const type = referenceType as EAppReferenceType;
    return {
      type,
      id
    };
  }

  static build(reference: AppTypedReferenceString): AppTypedReference {
    const value = AppTypedReference.buildValue(reference);
    return new AppTypedReference( value );
  }

  public equals(other: AppTypedReference): boolean {
    if(!other) {
      return false;
    }

    if(other === this) {
      return true;
    }

    if(other.value.type === this.value.type &&
      other.value.id === this.value.id) {
      return true;
    }
    return false;
  }

  protected onSetValue(value: IAppTypedReference | null) {
    this.toString = null;
    if(!this.value) {
      return;
    }
    this.toString = `${value.type}:${value.id}`;
  }

  constructor(value: IAppTypedReference) {
    super(value);
    if(value) {
      this.value = value;
    }
  }
}
