import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";
import {AppClusterNode} from "../app.cluster/AppClusterNode";

export class AuditScoreCalculator {
  private static _log: ILogger = LoggerFactory.build( 'AuditScoreCalculator' );

  calculate(audit: AppClusterNode) : number {
    let numerator:number = 0;
    let denominator:number = 0;

    for (let sectionIndex in audit.children) {
      const sectionNode = audit.children[sectionIndex];

      for (let subsectionIndex in sectionNode.children) {
        const subsectionNode = sectionNode.children[subsectionIndex];

        const subsection = subsectionNode?.cluster?.value;
        if (!subsection) {
          continue;
        }

        if (subsection.include) {
          const weight = subsection?.weight ?? 1;
          const score = subsection.score ?? 0;

          numerator += score * weight;
          denominator += weight;
        }
      }
    }

    return numerator / denominator;
  }
}
