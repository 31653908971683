<div cclass="question-text-container">

  <div cclass="question-text">
    <h3>
      {{questionLabel}}
    </h3>
  </div>

</div>

<input type="file"
       accept="image/*"
       (change)="onImageSelected($event)"
       id="imageInput"
       style="display: none"/>

<div cclass="add-photo-button-container">

  <div cclass="add-photo-button">

    <button cclass="photo" mat-raised-button color="light" style="height: 140px; width: 100%;"
            [disabled]="photos.imageSources2.length==maxImageCount">

      <label for="imageInput"
             style="width: 100%"
      >
        <div cclass="button-content">
          <div>
            <mat-icon style="font-size: 90px; width: 100%; height: 90px">
              camera_alt
            </mat-icon>
          </div>
          <div>
            Add a photo
          </div>
        </div>
      </label>

    </button>

  </div>

</div>


<div cclass="existing-photos-container">

  <div cclass="existing-photo" *ngFor="let imageSource of photos.imageSources2; let $index = index" >
    <img src="{{imageSource.storageUrl?imageSource.storageUrl:imageSource.base64Encoded}}">
    <button mat-raised-button color="primary" style="width: 100%"
            (click)="remove($index)">
      <mat-icon>delete</mat-icon>
      Remove
    </button>
  </div>
</div>



