import {ValueObject} from "./ValueObject";
import {EProductType, ProductTypeOptions} from "./ProductType";
import {ISearchable} from "../../common/interfaces/ISearchable";

export interface IAppPropertyMetaData {
  version?: number[];
}

export interface IAppProperty {
  _meta?: IAppPropertyMetaData;
  country: string;
  countryCode?: string;
  name: string;
  address: string[];
  email: string;
  productType: EProductType;
  clientPropertyIdentifier?: string;
}

export class AppProperty extends ValueObject<IAppProperty> implements ISearchable {

  private _searchableString: string = null;

  constructor(value: IAppProperty|null = null,
              public propertyKey: string|null = null) {
    super(value);
  }

  static array = {
    sortById: ( properties: AppProperty[] ) => {
      properties.sort( (a,b) => {
        return a.propertyKey.localeCompare( b.propertyKey );
      })
    }
  }

  static generatePropertyKey(country: string, name: string): string {
    if (!country || !name) {
      return null;
    }

    let propertyKey = `${country}-${name}`;

    propertyKey = propertyKey.toLowerCase();
    propertyKey = propertyKey.replace( /\W/g, '-' );
    propertyKey = propertyKey.replace( /_/g, '-' ); // replace underscores
    propertyKey = propertyKey.replace( /--+/g, '-' ); // strip duplicate '-'s

    // https://regex101.com/r/VJ9CmZ/1
    propertyKey = propertyKey.replace( /^-+/, '' ); // strip leading '-'s

    // https://regex101.com/r/cELrPF/1
    propertyKey = propertyKey.replace( /-+$/, '' ); // strip trailing '-'s

    return propertyKey;
  }

  get searchableString() : string {
    if(this._searchableString !== null) {
      return this._searchableString;
    }
    let address: string = "";
    if (this.value?.address) {
      address = this.value.address.join();
    }
    const product: string = ProductTypeOptions.getLabel(this.value?.productType);
    this._searchableString = `${this.value.name}${this.value?.clientPropertyIdentifier??''}${address}${product}`.toLowerCase();

    return this._searchableString;
  }

  get addressLabel() : string | null {
    if (!this.value?.address) {
      return;
    }
    return this.value.address.join(', ');
  }

  get label() : string | null {
    if (!this) {
      return;
    }

    if (!this.value?.clientPropertyIdentifier) {
      return this.value.name;
    }

    if (this.value.name.includes(this.value.clientPropertyIdentifier)) {
      return this.value.name;
    }

    return `${this.value.name} [${this.value.clientPropertyIdentifier}]`;
  }

  protected onSetValue(value: IAppProperty | null) {
  }
}
