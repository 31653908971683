import {Injectable, NgZone} from '@angular/core';
// vvv https://github.com/angular/angularfire2/issues/1015
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {HttpClient} from '@angular/common/http';
// ^^^ https://github.com/angular/angularfire2/issues/1015
import firebase from 'firebase/compat/app';
import storage = firebase.storage;
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {AngularFireUploadTask} from "@angular/fire/compat/storage/task";
import {UploadTaskSnapshot} from "@angular/fire/compat/storage/interfaces";
import {environment} from "../../../environments/environment";
import {BaseProxy} from "../../firebase/functions/BaseProxy";
import {BaseSessionContext} from "../../service.session-context/BaseSessionContext";
import {Command} from "../../../javascript.lib.mojo-base/util/Command";
import {LoggerFactory} from "../../../javascript.lib.mojo-base/log/LoggerFactory";
import {AppAnswer} from "../../../javascript.lib.mojo-base/model/AppAnswer";




export interface IPhotoUploadListener {
  onUploadCompleted( photoKey: number, remainingPhotos: number );
  onUploadFailed( photoKey: number, remainingPhotos: number );
  onUploadPaused( photoKey: number, bytesTransferred: number, totalBytes: number );
  onUploadRunning( photoKey: number, bytesTransferred: number, totalBytes: number );
}

export interface IImage {
  storageUrl?: string; // in storage
  base64Encoded?: string; // in memory base64 encoding
  blob?: any; // in memory blob
}

@Injectable()

export class PhotosProvider {

  private _log = LoggerFactory.build( 'PhotosProvider' );
  private _hasChanges = false;

  answer: AppAnswer = null;
  imageSources: string[] = [];
  imageSources2: IImage[] = [];

  constructor(public http: HttpClient,
              public afDb: AngularFireDatabase,
              private storage: AngularFireStorage,
              public sessionContext: BaseSessionContext
  ) {
  }

  hasPhotos(): boolean {

    if (!this.answer || !this.imageSources2) {
      return false;
    }

    return this.imageSources2.length > 0;
  }

  // returns whether the reset was redundant, i.e. the qa passed matched the current one in the `PhotosProvider`
  async init( clientKey:string, propertyKey: string, answer: AppAnswer ): Promise<boolean> {

    if (this.answer === answer) {
      return false;
    }

    this.answer = answer;
    this.imageSources = [];
    this.imageSources2 = [];
    this._hasChanges = false;

    await this.loadAllPhotos(clientKey, propertyKey);

    return true;
  }

  hasChanges(): boolean {
    if (!this.answer) {
      return false;
    }

    return this._hasChanges;
  }

  async saveAll( clientKey: string, propertyKey: string, listener: IPhotoUploadListener ) {
    if (!this._hasChanges) {
      return;
    }

    for ( let i = 0; i < this.imageSources2.length; i++ ) {
      const remainingPhotos = this.imageSources2.length - (i+1);
      const photoKey: number = this.answer.answer.getPhotoKeys()[i];
      try {

        const imageSource = this.imageSources2[i];
        if ( imageSource.base64Encoded ) {

          const downloadUrl = await this._save(imageSource, clientKey, propertyKey, photoKey);
          this._log.debug( 'downloadUrl', downloadUrl);
          listener.onUploadCompleted( photoKey, remainingPhotos )
        }

      } catch ( e ) {

        this._log.error( 'caught exception', e );
        listener.onUploadFailed( photoKey, remainingPhotos )
      }
    }

    this._hasChanges = false;
  }

  private async _cloudFunctionSave(image: IImage, clientKey: string, propertyKey: string, photoKey: number ): Promise<string> {

    const answer = new Command<string>();

    const path = `photos/${clientKey}/${propertyKey}/${this.answer.question.value.key}/${photoKey}`;

    const proxy = new BaseProxy(this.http);

    const idToken = await this.sessionContext.getIdToken();
    const options = await proxy.getAuthorizedOptions(idToken);

    const postUrl = `${environment.cloudFunctionsUrl}/uploadPhoto?path=${path}`;
    this._log.debug( 'postUrl', postUrl );
    await proxy.post( `${environment.cloudFunctionsUrl}/uploadPhoto?path=${path}`, image.blob, options );

    // listener.onUploadRunning( photoKey, 100, 100 );

    const imageRef = this.storage.ref( path );
    imageRef.getDownloadURL().subscribe( (url: string) => {


      this._log.info('save', 'completed', 'url', url );
      // listener.onUploadCompleted( photoKey, url );
      answer.resolve( url );

    });

    return answer.toPromise();

  }

  private _save( image: IImage, clientKey: string, propertyKey: string, photoKey: number ): Promise<string> {

    if ( !this.answer ) {
      this._log.warn( '!this.answer' );
      return Promise.reject( '!this.answer' );
    }

    //if(environment.cloudFunctionsUrl) {
      return this._cloudFunctionSave(image, clientKey, propertyKey, photoKey);
    // } else {
    //   return this._storageSave( image, clientKey, propertyKey, photoKey );
    // }

  }

  push(base64Encoded: string, blob: any) {

    this.imageSources.push(base64Encoded);
    this.imageSources2.push( {
      base64Encoded: base64Encoded,
      blob
    });

    if (!this.answer) {
      this._log.warn( 'push', '!this.questionAnswer' );
    } else {
      this.answer.answer.addPhotoKey();
    }

    this._hasChanges = true;
  }

  remove(index: number) {
    this.imageSources.splice(index, 1);
    this.imageSources2.splice(index, 1);

    this.answer.answer.removePhotoKeyAtIndex(index);
    this._hasChanges = true;
  }


  private async loadAllPhotos( clientKey: string, propertyKey: string ) {
    if ( !this.answer ) {
      return;
    }

    const photoKeys: number[] = this.answer.answer.getPhotoKeys();
    for ( const photoKey of photoKeys ) {
      const path = `photos/${clientKey}/${propertyKey}/${this.answer.question.value.key}/${photoKey}`;
      const imageRef =  this.storage.ref( path);
      try {
        const downloadURL = await imageRef.getDownloadURL().toPromise();
        this.imageSources.push(downloadURL);
        this.imageSources2.push({
          storageUrl: downloadURL
        });
      }
      catch (e) {
        this._log.error("Unable to download photo", "clientKey", clientKey, "propertyKey", propertyKey, "photoKey", photoKey);
      }
    }
  }
}
