<mat-sidenav-container aclass="sidenav-container">

  <mat-sidenav #sidenav2 aclass="sidenav" [mode]="'over'">
    <app-side-menu homeDisabled="true" [sidenav]="sidenav2">
    </app-side-menu>
  </mat-sidenav>

  <mat-sidenav-content>
    <div aclass="page-container">

      <mat-toolbar aclass="header-toolbar">
        <mat-toolbar-row>
          <button mat-button
                  (click)="sidenav2.toggle()">
            <span class="material-icons">menu</span>
          </button>
          <span style="flex: 1 1 auto;"></span>
          <img src="assets/mobility-mojo/logo-and-text.white.png" style="height: 34px;">
          <span style="flex: 1 1 auto;"></span>
          <div style="width: 64px; height: 10px;">
          </div>
        </mat-toolbar-row>
      </mat-toolbar>

      <div aclass="page-content">
        <password-reset
          (passwordResetTriggered)="onPasswordResetTriggered()"
          [emailAddress]="emailAddress"
        >
        </password-reset>
      </div>

      <mat-toolbar aclass="footer-toolbar">
        <mat-toolbar-row>
        </mat-toolbar-row>
      </mat-toolbar>

    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
